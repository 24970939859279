import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  value: [],
  exerciseCategories: [],
  filteredExerciseValues: [],
  counter: {
    age: 0,
    subject: 0,
    // tag: 0,
  },
};

export const exercisesSlice = createSlice({
  name: "exercises",
  initialState,
  reducers: {
    setExercises: (state, action) => {
      state.value = action.payload;
      state.filteredExerciseValues = action.payload;
    },
    setExerciseCategories: (state, action) => {
      state.exerciseCategories = action.payload.map((el) => {
        return { ...el, checked: false };
      });
    },

    setFilteredValues: (state, action) => {
      //update checked state
      state.exerciseCategories.find(
        (el) => el.id === action.payload.value.id
      ).checked = !action.payload.value.checked;

      state.exerciseCategories = [...state.exerciseCategories];
      //update counter
      state.counter.subject = state.exerciseCategories.filter(
        (cat) => cat.checked && cat.categoryType === "subject"
      ).length;
      state.counter.age = state.exerciseCategories.filter(
        (cat) => cat.checked && cat.categoryType === "age"
      ).length;

      //filter by subject
      let filteredResult = [];
      const selectedCategoriesId = current(state)
        .exerciseCategories.filter(
          (cat) => cat.checked && cat.categoryType === "subject"
        )
        .map((cat) => cat._id);
      if (selectedCategoriesId.length > 0) {
        filteredResult = state.value.filter((exercise) =>
          exercise.categories.some((cat) =>
            selectedCategoriesId.includes(cat._id)
          )
        );
      } else {
        filteredResult = [...state.value];
      }
      //filter by age
      const selectedAgeCategoriesId = current(state)
        .exerciseCategories.filter(
          (cat) => cat.checked && cat.categoryType === "age"
        )
        .map((cat) => cat._id);
      if (selectedAgeCategoriesId.length > 0) {
        state.filteredExerciseValues = filteredResult.filter((exercise) =>
          exercise.categories.some((cat) =>
            selectedAgeCategoriesId.includes(cat._id)
          )
        );
      } else {
        state.filteredExerciseValues = filteredResult;
      }
    },

    // setFilteredValues: (state, action) => {
    //   if (action.payload.type === "category") {
    //     state.exerciseCategories.find(
    //       (el) => el.id === action.payload.value.id
    //     ).checked = !action.payload.value.checked;
    //     state.exerciseCategories = [...state.exerciseCategories];
    //     let categoryType = state.exerciseCategories.find(
    //       (el) => el.id === action.payload.value.id
    //     ).categoryType;
    //     state.exerciseCategories.map((el) => {
    //       if (
    //         el.categoryType === categoryType &&
    //         el.id !== action.payload.value.id
    //       ) {
    //         el.checked = false;
    //       }
    //     });
    //   } else if (action.payload.type === "tag") {
    //     state.exerciseTags.find(
    //       (el) => el.value === action.payload.value.value
    //     ).checked = !action.payload.value.checked;
    //     state.exerciseTags = [...state.exerciseTags];
    //   } else {
    //     if (action.payload.includes("=") && action.payload.includes("?")) {
    //       let pathArr = action.payload
    //         .split("=")
    //         .filter((el) => el !== "")[1]
    //         .split(",")
    //         .filter((el) => el !== "");
    //       state.exerciseTags.map((el) => {
    //         if (pathArr.includes(el.value) && el.checked !== undefined) {
    //           el.checked = !el.checked;
    //         }
    //       });
    //     } else {
    //       let pathArr = action.payload.split("/").filter((el) => el !== "");
    //       state.exerciseCategories.map((el) => {
    //         if (pathArr.includes(el.slug.current) && el.checked !== undefined) {
    //           el.checked = !el.checked;
    //         }
    //       });
    //     }
    //   }

    //   let selectedTags = current(state).exerciseTags.filter((el) => el.checked);
    //   state.counter.tag = selectedTags.length;

    //   let selectedCategoriesSubject = current(state).exerciseCategories.filter(
    //     (el) => el.checked && el.categoryType === "subject"
    //   );
    //   state.counter.subject = selectedCategoriesSubject.length;

    //   let selectedCategoriesAge = current(state).exerciseCategories.filter(
    //     (el) => el.checked && el.categoryType === "age"
    //   );
    //   state.counter.age = selectedCategoriesAge.length;

    //   let arr = current(state).value.filter((el) => {
    //     const cat = el.categories;
    //     const tags = el.exerciseDifficulty;
    //     let isSubject = selectedCategoriesSubject.some((el) =>
    //       cat.find((elem) => elem.id === el.id)
    //     );
    //     let isAge = selectedCategoriesAge.some((el) =>
    //       cat.find((elem) => elem.id === el.id)
    //     );

    //     let isTag = selectedTags.some((el) => tags.includes(el.value));

    //     if (
    //       selectedCategoriesSubject.length !== 0 &&
    //       selectedCategoriesAge.length !== 0 &&
    //       selectedTags.length !== 0
    //     ) {
    //       return isSubject && isAge && isTag;
    //     } else if (
    //       selectedCategoriesSubject.length !== 0 &&
    //       selectedCategoriesAge.length !== 0 &&
    //       selectedTags.length === 0
    //     ) {
    //       return isSubject && isAge;
    //     } else if (
    //       selectedCategoriesSubject.length !== 0 &&
    //       selectedCategoriesAge.length === 0 &&
    //       selectedTags.length !== 0
    //     ) {
    //       return isSubject && isTag;
    //     } else if (
    //       selectedCategoriesSubject.length !== 0 &&
    //       selectedCategoriesAge.length === 0 &&
    //       selectedTags.length === 0
    //     ) {
    //       return isSubject;
    //     } else if (
    //       selectedCategoriesSubject.length === 0 &&
    //       selectedCategoriesAge.length !== 0 &&
    //       selectedTags.length !== 0
    //     ) {
    //       return isAge && isTag;
    //     } else if (
    //       selectedCategoriesSubject.length === 0 &&
    //       selectedCategoriesAge.length !== 0 &&
    //       selectedTags.length === 0
    //     ) {
    //       return isAge;
    //     } else if (
    //       selectedCategoriesSubject.length === 0 &&
    //       selectedCategoriesAge.length === 0 &&
    //       selectedTags.length !== 0
    //     ) {
    //       return isTag;
    //     } else {
    //       return true;
    //     }
    //   });
    //   state.filteredExerciseValues = arr;
    // },

    resetFilters: (state) => {
      state.exerciseCategories = state.exerciseCategories.map((el) => {
        return { ...el, checked: false };
      });
      state.filteredExerciseValues = state.value;
      state.activeFilters = [];
      state.counter = {
        age: 0,
        subject: 0,
        // tag: 0,
      };
    },
  },
});

export const {
  setExercises,
  setExerciseCategories,
  setFilteredValues,
  setFilteredExercises,
  resetFilters,
} = exercisesSlice.actions;

export default exercisesSlice.reducer;
