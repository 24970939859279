import "./assets/styles/global.css";
import "./assets/styles/global.scss";
export { default as wrapRootElement } from "./src/store/ReduxWrapper";

export function onRouteUpdate({ location, prevLocation }) {
  setTimeout(() => {
    if (window.location.hash === "") window.scrollTo(0, 0);
  }, [100]);

  //regex for page and filters
  // const regexTemplateUprazhnenia = /^\?page=\d+(?<!0)(?:&filters=[^&]*)?$/;

  //check if search param is in format ?page=<number > 0>
  // const regexTemplateBlog = /^\?page=\d+(?<!0)$/;

  // //blog
  // if (
  //   window.location.pathname === "/blog/" &&
  //   !regexTemplateBlog.test(window.location.search)
  // ) {
  //   window.location.search = "?page=1";
  // }

  // //uprazhnenia
  // if (
  //   window.location.pathname === "/uprazhnenia/" &&
  //   !regexTemplateBlog.test(window.location.search)
  // ) {
  //   window.location.search = "?page=1";
  // }
}
