import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [],
};

export const newsletterSlice = createSlice({
  name: "newsletter",
  initialState,
  reducers: {
    setNewsletterValue: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setNewsletterValue } = newsletterSlice.actions;
export default newsletterSlice.reducer;
