export const toPlainText = (blocks = []) => {
  return blocks
    .map((block) => {
      if (block._type !== "block" || !block.children) {
        return "";
      }
      return block.children.map((child) => child.text).join("");
    })
    .join("")
    .replace(/\n/g, " ");
};

export const complexedPlainText = (blocks = []) => {
  return blocks
    .map((block) => {
      if (block._type === "customQuotes" || block._type === "customText") {
        return toPlainText(block.content);
      } else if (block._type === "author") {
        return block.name;
      } else if (block._type === "block") {
        return block.children.map((child) => child.text).join("");
      }
      return "";
    })
    .join("")
    .replace(/\n/g, " ");
};
