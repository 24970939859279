import { createSlice } from "@reduxjs/toolkit";
import { complexedPlainText } from "../../../utils/ToPlainText";

const initialState = {
  articles: [],
  authors: [],
  exercises: [],
  metod: [],
  // categories: [],
  filteredValues: {
    articlesArr: [],
    authorsArr: [],
    exercisesArr: [],
    metodArr: [],
    // categoriesArr: [],
  },
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setArticles: (state, action) => {
      state.articles = action.payload;
    },
    setAuthors: (state, action) => {
      state.authors = action.payload;
    },
    setExercises: (state, action) => {
      state.exercises = action.payload;
    },
    setMontesoriMetod: (state, action) => {
      state.metod = action.payload;
    },
    // setCategories: (state, action) => {
    //   state.categories = action.payload;
    // },
    setFilteredValues: (state, action) => {
      if (action.payload !== "") {
        state.filteredValues.articlesArr = state.articles.filter(
          (article) =>
            article.title.toLowerCase().includes(action.payload) ||
            article?.author?.name?.toLowerCase().includes(action.payload) ||
            complexedPlainText(article._rawBody)
              .toLowerCase()
              .includes(action.payload)
        );

        state.filteredValues.authorsArr = state.authors.filter((author) =>
          author.name.toLowerCase().includes(action.payload)
        );

        state.filteredValues.metodArr = state.metod.filter((el) => {
          return (
            el.title.toLowerCase().includes(action.payload) ||
            complexedPlainText(el._rawDescription)
              .toLowerCase()
              .includes(action.payload)
          );
        });
        state.filteredValues.exercisesArr = state.exercises.filter(
          (exercise) => {
            const propertiesToCheck = [
              "heading",
              "educationSection",
              "aims",
              "categorySkills",
              "necessaryMaterials",
            ];

            return (
              propertiesToCheck.some((property) => {
                const currentValue = exercise[property];

                //check if the properties are array or not
                if (Array.isArray(currentValue)) {
                  return currentValue?.some((item) => {
                    const match = item.toLowerCase().includes(action.payload);
                    return match;
                  });
                }
                return currentValue?.toLowerCase().includes(action.payload);
              }) ||
              exercise.exerciseSteps.some(
                (el) =>
                  complexedPlainText(el._rawStepDescription)
                    .toLocaleLowerCase()
                    .includes(action.payload) ||
                  el?.stepHeading?.toLowerCase().includes(action.payload)
              ) ||
              exercise.variationsArray.some((variation) =>
                complexedPlainText(variation._rawVariationContent)
                  .toLocaleLowerCase()
                  .includes(action.payload)
              )
            );
          }
        );

        // state.filteredValues.categoriesArr = state.categories.filter(category => category.title.toLowerCase().includes(action.payload))
      } else {
        state.filteredValues = { ...initialState.filteredValues };
      }
    },
  },
});

export const {
  setArticles,
  setExercises,
  setAuthors,
  setMontesoriMetod,
  setFilteredValues,
} = searchSlice.actions;

export default searchSlice.reducer;
